import $ from "jquery";

function getOffcanvas() {
    return $("#menu-offcanvas");
}
function openMenu() {
    getOffcanvas().fadeIn(200);
}

function closeMenu() {
    getOffcanvas().fadeOut(200);
}

$(function() {
    const $offcanvas = getOffcanvas();
    $(".menubutton").on("click", openMenu);
    $offcanvas.find(".closebutton").on("click", closeMenu);
});

$(function() {
    $(".bl-button.openmenu").on("click", function(ev) {
        openMenu();
        ev.preventDefault();
    });
});