import $ from "jquery";
import "jquery.scrollto";
// npm install jquery.scrollto

$(function() {
    $(".bl-hero .scrolldownarrow").on("click", function() {
        const $scrolldownindicator = $(this);
        const $herostart = $scrolldownindicator.closest(".bl-hero");
        const $nextElement = $herostart.next();
        $(window).scrollTo($nextElement, 500);
    });
});

